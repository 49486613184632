/* These styles make the body full-height */
html, body {
    height: 100%;
}

/* These styles disable body scrolling if you are using <ScrollView> */
body {
    overflow: hidden;
    font-family: 'Roboto', sans-serif;
}

/* These styles make the root element full-height */
#root {
    display: flex;
    height: 100%;
}

*:focus {
    outline: none !important;
}

*::-webkit-scrollbar {
    background: transparent;
    border: none;
    width: 8px;
    height: 5px;
    transition: 500ms;
}

*::-webkit-scrollbar-track {
    background: #f5f5f53d;
    border-radius: 0px;
}

*::-webkit-scrollbar-thumb {
    background: rgba(3, 3, 3, 0.3);
    border-radius: 0px;
    height: 100px !important;
}

img {
    width: 2rem;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

/*animations*/
@keyframes fadeIn{
    0% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut{
    0% {
        opacity: 1;
    }
    100% {
        opacity: .5;
    }
}

@keyframes fadeToTop{
    0% {
        bottom: -10px;
        opacity: .5;
    }
    100% {
        bottom: 10px;
        opacity: 1;
    }
}
